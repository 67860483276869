import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
    return (
        <main>
            <Link to="/">Go to home page</Link>
        </main>
    );
};

export default NotFoundPage;
